import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import Subscribe from 'components/share/subscribe/index'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faRss } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faYoutubeSquare, faTiktok, faXTwitter } from '@fortawesome/free-brands-svg-icons'

import styles from './footer.module.scss'

function Footer (props) {
  const [isSubscribe, setIsSubscribe] = useState(true)

  useEffect(() => {
    if (props.isSubscribe !== undefined) {
      setIsSubscribe(props.isSubscribe)
    }
  }, [props])

  return (
    <footer>
      { isSubscribe && <Subscribe />}
      <section className={`${styles.footer} d-flex justify-content-between`}>
        <div className='card no-border'>
          <Image className='card-img-top' src={'/static/images/list-now.jpg'} width={98} height={99} alt='Listen' loading='lazy' />
          <div className='card-body'>
            <Button href='/blog/podcasts' target="__blank" variant='danger' className='btn-dark clearfix'>Listen to podcast</Button>
          </div>
        </div>
        <div className={styles.footer__social}>
          <Image className={styles.footer__logo} src='/static/images/logo.svg' alt={'The Dolce Diet'} width={199} height={53}
          />
          <Nav className={`nav ${styles.footer__menu}`}>
            <Nav.Item>
              <Nav.Link href='/contact'>
                Contact Us
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href={'/privacy-policy'}>
                Privacy Policy
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href={'/privacy-policy?policy=returns'}>
                Returns
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href={'/privacy-policy?policy=shipping'}>
                Shipping
              </Nav.Link>
            </Nav.Item>
            <ul className="mobile mt-3 ms-auto ms-auto w-100 m-0 p-0">
              <Nav.Item as='li' className="d-inline-block mt-1">
                <Nav.Link
                  className='nav-link'
                  name={'dolce-youtube'}
                  href='https://shop.thedolcediet.com/'
                  target='_blank'
                  rel="noreferrer"
                >
                  <FontAwesomeIcon className='fs-7 me-2' icon={faCartShopping}/> Browse Shop
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as='li' className="d-inline-block mt-1 ms-3">
                <Nav.Link
                  className='nav-link'
                  name={'dolce-youtube'}
                  href='/blog/podcasts'
                  target='_blank'
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faRss} className={'fas'} /> Listen to podcast
                </Nav.Link>
              </Nav.Item>
            </ul>
          </Nav>
          <ul className={`nav ${styles['footer__social-list']}`}>
            <li className='nav-item'>
              <a
                name={'dolce-facebook'}
                className='nav-link'
                href='https://facebook.com/thedolcediet'
                target='_blank'
                rel="noreferrer"
                aria-label="Facebook"
              >
                <FontAwesomeIcon icon={faFacebook} className={'fab'} />
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link'
                name={'dolce-twitter'}
                href='https://twitter.com/thedolcediet'
                target='_blank'
                rel="noreferrer"
                aria-label="Twitter"
              >
                <FontAwesomeIcon icon={faXTwitter} className={'fab'} />
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link'
                name={'dolce-instagram'}
                href='https://instagram.com/thedolcediet'
                target='_blank'
                rel="noreferrer"
                aria-label='Dolce Instagram'
              >
                <FontAwesomeIcon icon={faInstagram} className={'fab'} />
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link'
                name={'dolce-youtube'}
                href='https://www.youtube.com/dolcediet'
                target='_blank'
                rel="noreferrer"
                aria-label='Dolce Youtube'
              >
                <FontAwesomeIcon icon={faYoutubeSquare} className={'fab'}/>
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link'
                name={'dolce-tiktok'}
                href='https://www.tiktok.com/@thedolcediet'
                target='_blank'
                rel="noreferrer"
                aria-label='Dolce Diet Tiktok'
              >
                <FontAwesomeIcon icon={faTiktok} className={'fab'}/>
              </a>
            </li>
          </ul>
        </div>
        <div className='card no-border'>
          <Image className={'card-img-top'} src={'/static/images/shop-now.jpg'} width={98} height={99} alt='Shop' loading='lazy' />
          <div className='card-body'>
            <Button name={'dolce-shop'} href='https://shop.thedolcediet.com/' target="__blank" variant='danger' className='btn-dark clearfix'>
              <Image src='/static/images/Shop_icon.svg' alt="shop now icon" width={20} height={20} className="fa me-2"/>
              Shop now
            </Button>
          </div>
        </div>
      </section>
    </footer>
  )
}

Footer.propTypes = {
  isSubscribe: PropTypes.bool
}

export default Footer
