import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import Form from 'react-bootstrap/Form'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Button from 'react-bootstrap/Button'
import AnimationMenu from 'plugins/animation-menu'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faCartShopping, faCircleUser, faBars } from '@fortawesome/free-solid-svg-icons'
import zenscroll from 'zenscroll'

import styles from './header.module.scss'
import useMobileDetect from '../../../helpers/mobile-detect'

function CustomDropdownMenu (props) {
  const [show, setShow] = useState(false)
  const currentDevice = useMobileDetect()
  const handleToggleMenu = (e) => {
    e.preventDefault()
    if (show) {
      setShow(false)
    } else {
      setShow(true)
    }
  }

  const showMenu = (e) => {
    e.preventDefault()
    // on Mobile : not expand dropdown menu
    const element = document.querySelector('.navbar-collapse.show')
    !element && setShow(true)
  }

  return (
    <div
      className="dropdown nav-item"
      onMouseOver={showMenu}
      onMouseLeave={() => {
        if (currentDevice.width > 768) {
          setShow(false)
        }
      }}
    >
      <span
        aria-haspopup="true"
        aria-expanded="false"
        id="basic-nav-dropdown"
        className="dropdown-toggle nav-link"
        role="button"
      >
        <a href={props.href}>{props.title}</a>
        <FontAwesomeIcon
          icon={show ? faChevronUp : faChevronDown}
          className={'fa ms-2'}
          onClick={handleToggleMenu}
          style={{ maxWidth: 14 }}
        />
      </span>
      <div
        aria-labelledby="basic-nav-dropdown"
        className={`dropdown-menu ${show ? 'show' : ''}`}
      >
        {props.children}
      </div>
    </div>
  )
}

function Header (props) {
  const router = useRouter()
  const [load, setLoad] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [menus, setMenus] = useState([
    {
      value: 'about-the-diet-plan',
      text: 'Our Programs',
      href: '',
      children: []
    },
    {
      value: 'testimonials',
      text: 'Testimonials',
      href: '',
      children: []
    },
    {
      value: 'who-is-mike-dolce',
      text: 'About Mike',
      href: '',
      children: []
    },
    {
      value: 'content',
      text: 'Content',
      href: `${process.env.NEXT_PUBLIC_API_BASE}/blog`,
      children: [
        {
          value: 'all',
          text: 'all',
          href: `${process.env.NEXT_PUBLIC_API_BASE}/blog`
        },
        {
          value: 'podcasts',
          text: 'podcasts',
          href: `${process.env.NEXT_PUBLIC_API_BASE}/blog/podcasts`
        },
        {
          value: 'blog',
          text: 'blog',
          href: `${process.env.NEXT_PUBLIC_API_BASE}/blog/news`
        },
        {
          value: 'videos',
          text: 'videos',
          href: `${process.env.NEXT_PUBLIC_API_BASE}/blog/video`
        },
        {
          value: 'weightcutting',
          text: 'weightcutting',
          href: `${process.env.NEXT_PUBLIC_API_BASE}/blog/weightcutting`
        }
      ]
    }
  ])

  useEffect(() => {
    if (!load) {
      setLoad(true)
    }
    AnimationMenu({ load: load })
  }, [load])

  const renderMenu = () => {
    return menus.map((menu, index) =>
      menu.children.length > 0 ? (
        <CustomDropdownMenu key={index} title={menu.text} href={menu.href}>
          {menu.children.map((item, indexChild) => {
            return (
              <NavDropdown.Item key={indexChild} href={item.href}>
                {item.text}
              </NavDropdown.Item>
            )
          })}
        </CustomDropdownMenu>
      ) : (
        <Nav.Item
          key={index}
          className={router.pathname.includes(menu.value) ? 'active' : ''}
        >
          <Nav.Link href={`/${menu.value}`}>{menu.text}</Nav.Link>
        </Nav.Item>
      )
    )
  }

  const handleMenuMobile = (e) => {
    const element = document.querySelector('.screen-overlay')
    const topMenu = document.querySelector('.top-menu')
    if (element) {
      if (element.classList.contains('show')) {
        element.classList.remove('show')
      } else {
        element.classList.add('show')
      }
    }
    if (topMenu) {
      if (topMenu.classList.contains('show')) {
        topMenu.classList.remove('show')
      } else {
        topMenu.classList.add('show')
      }
    }
  }

  const scrollToElement = (e) => {
    e.preventDefault()
    const element = document.querySelector('#choose-program')
    if (element) {
      zenscroll.to(element)
    } else {
      window.location = '/about-the-diet-plan#choose-program'
    }
  }

  return (
    <header id="main-menu" className={`fixed-top ${styles.header}`}>
      <div className={'screen-overlay'}></div>
      <div className="container-fluid">
        <Navbar bg="transparent" expand="lg" className={styles['top-menu']}>
          <Navbar.Brand href="/">
            <Image
              className={styles.logo}
              src="/static/images/logo.svg"
              alt="Dolce diet"
              width='199'
              height='53'
            />
          </Navbar.Brand>
          <div className="text-right mobile d-flex d-lg-none align-items-center">
            <button
              className="btn mobile bg-red get-started me-2"
              onClick={scrollToElement}
            >
              Get started
            </button>
            <a
              className="login-mobile no-border px-2"
              rel="noreferrer"
              target={'_blank'}
              href={'https://myprogram.thedolcediet.com/'}
              aria-label="login on mobile"
            >
              <FontAwesomeIcon className='fs-5' icon={faCircleUser}/>
            </a>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="navbar-dark no-border py-0 py-1 m-0"
              onClick={handleMenuMobile}
            >
              <FontAwesomeIcon className="text-white" icon={faBars}></FontAwesomeIcon>
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse id="basic-navbar-nav" className="mobile-offcanvas">
            <Nav className="ms-5 me-auto flex-wrap flex-fill">
              <Nav.Item>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  className="navbar-dark no-border close-menu"
                  onClick={handleMenuMobile}
                >
                  <Image alt="nav-close" src="/static/images/nav-close.svg" width={19} height={18}/>
                </Navbar.Toggle>
              </Nav.Item>
              {renderMenu()}
            </Nav>
            <Form className="d-flex justify-content-end flex-nowrap">
              <a
                className="btn no-border d-flex align-items-center"
                href="https://shop.thedolcediet.com/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className='fs-5 me-2' icon={faCartShopping}/>
                Browse Shop
              </a>
              <a
                href="https://myprogram.thedolcediet.com/#/login"
                target="__blank"
                className="no-background no-border login d-flex align-items-center"
              >
                <FontAwesomeIcon className='fs-5 me-2' icon={faCircleUser}/> Login
              </a>
              <div className="text-right mt-3 mt-lg-0">
                <Button
                  variant="danger"
                  className="btn bg-red get-started"
                  onClick={scrollToElement}
                >
                  Get started
                </Button>
              </div>
            </Form>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  )
}

CustomDropdownMenu.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.any
}

export default Header
