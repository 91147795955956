export default function AnimationMenu ({ load }) {
  if (load) {
    window.onscroll = function () {
      const posScroll = document.documentElement.scrollTop
      const mainMenu = document.getElementById('main-menu')

      if (!mainMenu) return
      if (posScroll > 50 && mainMenu) {
        mainMenu.classList.add('fixed-header')
      } else {
        mainMenu.classList.remove('fixed-header')
      }
    }
  }
}
