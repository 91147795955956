import React from 'react'

import styles from './loading.module.scss'

export default function DolceLoading (props) {
  return (
    <div className={`dolce-loading ${styles['dolce-loading']} loading ${props.center ? styles['dolce-loading--center'] : ''}`}
      style={{ display: `${props.isShow ? 'block' : 'none'}` }}>
      <div className={styles['dolce-loading__spinner']}>
        <span className={`${styles['dolce-loading__simple']} ${styles['is-loading']} ${styles['is-gray']} pull-left`}>Loading...</span>
      </div>
    </div>
  )
}
