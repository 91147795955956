import { useEffect, useState } from 'react'

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0])
  useEffect(() => {
    function updateSize () {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return size
}

const getMobileDetect = (userAgent) => {
  const [width, height] = useWindowSize()
  const isAndroid = () => Boolean(userAgent.match(/Android/i))
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i))
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i))
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i))
  const isSSR = () => Boolean(userAgent.match(/SSR/i))
  const isMobile = () =>
    Boolean(isAndroid() || isIos() || isOpera() || isWindows())
  const isDesktop = () => Boolean(!isMobile() && !isSSR())
  const isSafari = () => Boolean(userAgent.match(/Safari/i)) && !isChrome()
  const isChrome = () => Boolean(userAgent.match(/Chrome/i))
  const isFirefox = () => Boolean(userAgent.match(/Firefox/i))

  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
    width,
    height,
    isSafari,
    isChrome,
    isFirefox
  }
}

const useMobileDetect = () => {
  useEffect(() => { }, [])
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
  return getMobileDetect(userAgent)
}
export default useMobileDetect
