import React, { useEffect, useState, Fragment } from 'react'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import Header from './header/index'
import Footer from './footer/index.js'
import { useRouter } from 'next/router'
import { SettingTheme } from './setting'
import PropTypes from 'prop-types'
import Script from 'next/script'

const SUFooter = dynamic(() => import('./sign-up/footer/index'))
const SUHeader = dynamic(() => import('./sign-up/header/index'))

function Layout (props) {
  const [isFooter, setIsFooter] = useState(true)

  const router = useRouter()
  const isSignup =
    router.pathname.includes('sign-up') ||
    router.pathname.includes('ll-bundle-checkout') ||
    router.pathname.includes('ll-credit-checkout') ||
    router.pathname.includes('ll-paypal-checkout') ||
    router.pathname.includes('ll-applepay-checkout') ||
    router.pathname.includes('ll-venmo-checkout') ||
    router.pathname.includes('paypal') ||
    router.pathname.includes('credit') ||
    router.pathname.includes('apple') ||
    router.pathname.includes('venmo') ||
    router.pathname.includes('applepay-checkout')

  useEffect(() => {
    if (props.isFooter !== undefined) {
      setIsFooter(props.isFooter)
    }
    SettingTheme({ color: '#a50027' })
  }, [props])

  const renderFooter = () => {
    if (isFooter) {
      return isSignup ? (
        <SUFooter />
      ) : (
        <Footer isSubscribe={props.isSubscribe} />
      )
    }
  }

  return (
    <Fragment>
      <Head>
        <title>{props.title}</title>
        <meta
          name="viewport"
          content="initial-scale=1.0, maximum-scale=5.0, minimum-scale=1.0, width=device-width"
        />
        <meta name="description" content={props.description} />
        {props.noIndex && <meta name="robots" content="noindex" />}
        <link src="https://connect.facebook.net" crossOrigin="true" />
        <meta
          name="facebook-domain-verification"
          content="o84ou68cxid23zac7quhqpn9uo4udg"
        />
      </Head>
      <Script
        id="client"
        src="https://js.braintreegateway.com/web/3.66.0/js/client.min.js"
      />
      {isSignup && <Script
        id="paypal-checkout"
        src="https://js.braintreegateway.com/web/3.66.0/js/paypal-checkout.min.js"
      />}
      {isSignup && <Script
        id="venmo"
        src="https://js.braintreegateway.com/web/3.66.0/js/venmo.min.js"
      />}
      {isSignup && <Script
        id="apple-pay"
        src="https://js.braintreegateway.com/web/3.66.0/js/apple-pay.min.js"
      />}
      {isSignup && <Script
        id="data-collector"
        src="https://js.braintreegateway.com/web/3.66.0/js/data-collector.min.js"
      />}
      {props.h1 && <h1 className={'d-none'}>{props.h1}</h1>}
      {isSignup ? <SUHeader /> : <Header />}
      <div className={props.className || 'body'}>{props.children}</div>
      {renderFooter()}
      <div id="fb-root"></div>
    </Fragment>
  )
}

Layout.propTypes = {
  isFooter: PropTypes.bool,
  isSubscribe: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  h1: PropTypes.string,
  noIndex: PropTypes.bool
}

Layout.defaultProps = {
  title: 'Blog | Fitness News | Health Articles | Healthy Meal Plan | Diet',
  description:
    'The Dolce Blog is a hub of information regarding fitness, nutrition, training, recovering, MMA, sports, and new methods and techniques in human optimization',
  h1: ''
}

export default Layout
