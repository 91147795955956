import axios from 'axios'

axios.interceptors.request.use(function (config) {
  config.baseURL = API_URL
  if (typeof window === 'undefined') {
    config.withCredentials = true
    config.headers.Cookie = 'split_test_version=dolce_new_version'
  }
  return config
})

export default axios
